'use strict';

import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';

export default function SelectVariant(props) {
    const [show, setShow] = useState(false);
    const [variants, setVariants] = useState([]);

    useEffect(() => {
        if (variants) {
            setShow(true);
        }
    }, [variants, props.time]);

    useEffect(() => {
        axios.get(`/api/products/${props.id}/variants`).then(response => {
            setVariants(response.data);
        });
    }, [props.id]);

    const select = useCallback(variant => {
        props.insert(variant);
        setShow(false);
    }, []);

    const toggle = useCallback(() => {
        setShow( ! show);
    }, [show, setShow]);

    return (
        <Modal show={show} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>Какой вариант добавить в корзину?</Modal.Title>
            </Modal.Header>

                <Modal.Body>
                    <ListGroup>
                        {variants.map(variant => {
                            return <ListGroup.Item key={variant.id} action onClick={select.bind(this, variant)} className={variant.is_default ? 'font-weight-bold' : ''}>
                                {variant.is_default ? '★' : ''} <strong>{variant.title}</strong> ({variant.price} {AppCurrency.symbol}) {variant.is_default ? ' – Стандартный объем' : ''}
                            </ListGroup.Item>;
                        })}
                    </ListGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={toggle}>
                        Закрыть
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}
