'use strict';

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import SelectVariant from './SelectVariant';

export default function BuyButton(props) {
    const checkIsAdded = useCallback(() => {
        return window.AppCart.contents.findIndex((content) => {
            return ~~ content.product_id === ~~ props.id
                && (content.variant_id ? ~~ content.variant_id === ~~ props.variant.id : ! props.variant);
        }) !== -1;
    }, [window.AppCart.contents, props.id, props.variant]);

    const [isAdded, setIsAdded] = useState(useMemo(checkIsAdded, [props.id, props.variant.id]));
    const [showVariants, setShowVariants] = useState(false);
    const [showVariantsTime, setShowVariantsTime] = useState(0);

    useEffect(() => {
        setIsAdded(checkIsAdded());
    }, [props.id, props.variant]);

    const insert = useCallback(variant => {
        axios.post('/cart/insert', { id: props.id, variant: variant.id }).then((response) => {
            setIsAdded(true);
            window.AppCart.refresh();

            // Facebook Pixel
            if (typeof fbq !== 'undefined' && fbq) {
                fbq('track', 'AddToCart', {
                    content_type: 'product',
                    content_ids: variant.id
                });
            }

            // Google Analytics
            if (typeof gtag !== 'undefined' && gtag) {
                gtag('event', 'Add', {
                    'event_category': 'Cart'
                });
            }

            // Yandex Metrika
            if (typeof dataLayer !== 'undefined' && dataLayer) {
                dataLayer.push({
                    ecommerce: {
                        currencyCode: 'UAH',
                        add: {
                            actionField: {
                                goal_id: 133006663
                            },
                            products: [
                                {
                                    id: variant.id,
                                    name: response.data.content.product.name,
                                    variant: response.data.content.variant.title,
                                    price: response.data.content.price,
                                    brand: response.data.content.product.manufacturer.name,
                                    category: response.data.content.product.category.name,
                                    quantity: 1
                                }
                            ]
                        }
                    }
                });
            }
        });
    }, [props.id, setIsAdded]);

    const buy = useCallback(() => {
        if (props.hasMoreVariants) {
            setShowVariants(true);
            setShowVariantsTime(Date.now());
            return;
        }

        insert(props.variant);
    }, [props.id, props.variant, props.hasMoreVariants]);

    return <>
        {showVariants && <SelectVariant id={props.id} insert={insert} time={showVariantsTime} />}

        <button
            className={"btn "+ (isAdded ? 'btn-secondary' : 'btn-success')}
            onClick={isAdded ? () => {} : buy}
            style={{pointerEvents: (isAdded ? 'none' : '')}}
        >
            {isAdded
                ? <>В корзине</>
                : <>{ ! props.isThin ? <i className="fas fa-cart-plus mr-2"></i> : ''} Купить</>}
        </button>
    </>;
}

const buttons = document.getElementsByClassName('js-buy-button');

if (buttons.length > 0) {
    Array.from(buttons).forEach(element => {
        ReactDOM.render(<BuyButton
            id={element.dataset.id}
            variant={JSON.parse(element.dataset.variant)}
            hasMoreVariants={element.dataset.moreVariants === 'true'}
            status={JSON.parse(element.dataset.status)}
            isThin={Array.from(element.classList).indexOf('is-thin') !== -1}
        />, element);
    });
}
